import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

/**
 * Data of icon to import.
 */
interface IconData {
  /**
   * Icon name.
   */
  name: string;
  /**
   * Path to icon.
   */
  path: string;
}

/**
 * Registers additional application icons.
 */
@Injectable({
  providedIn: 'root',
})
export class IconsService {
  /**
   * @constructor
   * @param iconRegistry Material icons registry.
   * @param sanitizer DOM sanitizer.
   */
  public constructor(
    private readonly iconRegistry: MatIconRegistry,
    private readonly sanitizer: DomSanitizer,
  ) { }

  private readonly icons: IconData[] = [
    {
      name: 'validation-good',
      path: 'assets/icons/validation-good.svg',
    },
    {
      name: 'validation-bad',
      path: 'assets/icons/validation-bad.svg',
    },
    {
      name: 'validation-warning',
      path: 'assets/icons/validation-warning.svg',
    },
    {
      name: 'note',
      path: 'assets/icons/note.svg',
    },
    {
      name: 'check-mark',
      path: 'assets/icons/check-mark.svg',
    },
  ];

  /**
   * Register all icons.
   */
  public registerIcons(): void {
    this.icons.forEach(icon => {
      this.registerIcon(icon);
    });
  }

  private registerIcon(icon: IconData): void {
    this.iconRegistry.addSvgIcon(
      icon.name,
      this.sanitizer.bypassSecurityTrustResourceUrl(icon.path),
    );
  }
}
