import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ProjectorType } from '../../enums';
import { Projector } from '../../models';
import { AppConfigService } from '../app-config.service';
import { ProjectorDto } from '../mappers/dto/projector-dto';
import { ProjectorMapper } from '../mappers/projector.mapper';

/**
 * Service to work with products API.
 */
@Injectable({
  providedIn: 'root',
})
export class ProductsService {
  private readonly projectorsUrl = `${this.config.apiUrl}/api/v1/products/projectors/`;

  /**
   * @constructor
   */
  constructor(
    private readonly httpClient: HttpClient,
    private readonly config: AppConfigService,
    private readonly projectorMapper: ProjectorMapper,
  ) { }

  /**
   * Get list of suggestion items.
   */
  public getProjectors(projectorType: ProjectorType): Observable<Projector[]> {
    const illumination = ProjectorType.getIllumination(projectorType);
    const resolution = ProjectorType.getResolution(projectorType);

    return this.httpClient.get<ProjectorDto[]>(this.projectorsUrl, {
      params: {
        width: resolution.width.toString(),
        height: resolution.height.toString(),
        illumination: illumination,
      },
    }).pipe(
      map(items => items.map(item => this.projectorMapper.fromDto(item))),
    );
  }
}
