import { Component, ChangeDetectionStrategy, ViewEncapsulation, Input } from '@angular/core';
import { TooltipPosition } from '@angular/material/tooltip';

/**
 * Tooltip button component.
 */
@Component({
  selector: 'cpcac-tooltip-button',
  templateUrl: './tooltip-button.component.html',
  styleUrls: ['./tooltip-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class TooltipButtonComponent {
  /** Tooltip text */
  @Input()
  public text = 'No text';

  /** Icon */
  @Input()
  public customIcon?: string;

  /** Tooltip position. */
  @Input()
  public position: TooltipPosition = 'below';
}
