import { ObservableInput, MonoTypeOperatorFunction, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AppError } from '../models/app-error';

/**
 * Catch any application error (instance of AppError) operator.
 * Catches only AppValidationError<T> errors.
 * @param selector Selector.
 */
export function catchAppError<T, O extends ObservableInput<any>>(
  selector: (error: AppError) => O): MonoTypeOperatorFunction<T> {
  return catchError(error => {
    if (error instanceof AppError) {
      return selector(error);
    }
    return throwError(error);
  });
}
