<cpcac-calculations-table [class.export-table]="exportMode">
  <thead *ngIf="exportMode">
    <th translate>section-header.hazard-distance</th>
    <th translate>hazard-distance.distance</th>
  </thead>
  <tbody *ngLet="(units$ | async) || '' | translate as units">
    <tr>
      <td>B</td>
      <td>
        <ng-container *ngIf="(values$ | async)?.hdB as hdB"> {{ hdB | roundNumber : '2' }} {{ units }} </ng-container>
      </td>
      <td *ngIf="!exportMode"></td>
      <td *ngIf="!exportMode">
        <cpcac-table-note [text]="'table.notes.hazard-distance-values-are-subject-to-change' | translate" [exportMode]="exportMode"> </cpcac-table-note>
      </td>
    </tr>

    <tr>
      <td>D ({{ 'hazard-distance.usa' | translate }})</td>
      <td>
        <ng-container *ngIf="(values$ | async)?.hdDUsa as hdDUsa"> {{ hdDUsa | roundNumber : '2' }} {{ units }} </ng-container>
      </td>
      <td *ngIf="!exportMode"></td>
      <td *ngIf="!exportMode"></td>
    </tr>

    <tr>
      <td>D ({{ 'hazard-distance.out-of-usa' | translate }})</td>
      <td>
        <ng-container *ngIf="(values$ | async)?.hdDNonUsa as hdDNonUsa"> {{ hdDNonUsa | roundNumber : '2' }} {{ units }} </ng-container>
      </td>
      <td *ngIf="!exportMode"></td>
      <td *ngIf="!exportMode"></td>
    </tr>
  </tbody>
</cpcac-calculations-table>
