import { Observable, OperatorFunction } from 'rxjs';
import { map } from 'rxjs/operators';

/**
 * Pick properties from Observable.
 */
export function pickProperties<T, K extends keyof T>(...keys: K[]): OperatorFunction<T, Pick<T, K>> {
  return (source: Observable<T>): Observable<Pick<T, K>> =>
    source.pipe(
      map(obj => keys.reduce((accum, key) =>
        ({ ...accum, [key]: obj[key] }),
        {} as any),
      ),
    );
}
