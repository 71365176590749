<div class="note">
  <span class="note-text" [title]="text" [class.nowrap]="!exportMode" [class.hide-on-mobile]="!exportMode">
    {{ text }}
  </span>
  <cpcac-tooltip-button
    *ngIf="!exportMode"
    [class.hide-on-desktop]="!exportMode"
    customIcon="note"
    [text]="text">
  </cpcac-tooltip-button>
  <mat-icon
    *ngIf="!exportMode && icon"
    [svgIcon]="icon">
  </mat-icon>
</div>
