import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { distinctUntilChanged, map, shareReplay } from 'rxjs/operators';

import { COLOR_CORRECTION_EFFICIENCY_PERCENT, LENS_EFFICIENCY_PERCENT } from '../constants';
import { LensOptions, IlluminationType } from '../enums';
import { EfficiencyPercentValues } from '../models';
import { combineValues, pickProperties, pickValue } from '../rxjs/public_api';

import { CalculationsFormService } from './calculations-form.service';

/**
 * Calculations service.
 */
@Injectable({
  providedIn: 'root',
})
export class EfficiencyPercentCalculationsService {
  /**
   * @constructor
   * @param formService Form service.
   */
  public constructor(private readonly formService: CalculationsFormService) { }

  private readonly defaultValues = {
    colorCorrectionEfficiencyPercent: 0,
    lensEfficiencyPercent: 0,
    lumensHeadroomPercent: 0,
  };

  private readonly lumensHeadroomValue$ = combineValues([
    this.formService.illumination$,
    this.formService.values$.pipe(pickProperties('powerHeadroom', 'lightDropOff')),
  ]).pipe(
    map(([illumination, { powerHeadroom, lightDropOff }]) => {
      let { lumensHeadroomPercent, colorCorrectionEfficiencyPercent } = this.defaultValues;

      switch (illumination) {
        case IlluminationType.Laser:
          lumensHeadroomPercent = powerHeadroom;
          colorCorrectionEfficiencyPercent = COLOR_CORRECTION_EFFICIENCY_PERCENT.LASER;
          break;
        case IlluminationType.Xenon:
          lumensHeadroomPercent = lightDropOff;
          colorCorrectionEfficiencyPercent = COLOR_CORRECTION_EFFICIENCY_PERCENT.XENON;
          break;
        default:
          break;
      }

      return { lumensHeadroomPercent, colorCorrectionEfficiencyPercent };
    }),
  );

  private readonly lensEfficiencyValues$ = this.formService.values$.pipe(
    pickValue('lensOptions'),
    distinctUntilChanged(),
    map(option => {
      let { lensEfficiencyPercent } = this.defaultValues;

      switch (option) {
        case LensOptions.HighBrightness:
        case LensOptions.HighBrightnessPremium:
          lensEfficiencyPercent = LENS_EFFICIENCY_PERCENT.HB_LENSES;
          break;
        case LensOptions.HighContrast:
        case LensOptions.HighContrastPremium:
          lensEfficiencyPercent = LENS_EFFICIENCY_PERCENT.HC_LENSES;
          break;
        case LensOptions.UltraHighContrast:
        case LensOptions.UltraHighContrastPremium:
          lensEfficiencyPercent = LENS_EFFICIENCY_PERCENT.UHC_LENSES;
          break;
        default:
          break;
      }

      return { lensEfficiencyPercent };
    }),
  );

  /** Base calculations values. */
  public readonly values$: Observable<EfficiencyPercentValues> = combineValues([
    this.lensEfficiencyValues$,
    this.lumensHeadroomValue$,
  ]).pipe(
    map((values) => Object.assign({}, ...values)),
    shareReplay({ bufferSize: 1, refCount: true }),
  );
}
