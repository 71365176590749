import { Observable, OperatorFunction } from 'rxjs';
import { map } from 'rxjs/operators';

/**
 * Pick property value from Observable.
 */
export function pickValue<T, K extends keyof T>(key: K): OperatorFunction<T, T[K]> {
  return (source: Observable<T>): Observable<T[K]> =>
    source.pipe(
      map(obj => obj[key]),
    );
}
