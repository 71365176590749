import { Component, ChangeDetectionStrategy, ViewEncapsulation, Input } from '@angular/core';

/**
 * Component for displaying note about calculation
 */
@Component({
  selector: 'cpcac-table-note',
  templateUrl: './table-note.component.html',
  styleUrls: ['./table-note.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class TableNoteComponent {
  /** Note text */
  @Input()
  public text = '';

  /** Is table in export mode */
  @Input()
  public exportMode = false;

  /** Validation icon */
  @Input()
  public icon?: string;
}
