import { Injectable } from '@angular/core';

import { Lamp } from '../../models/lamp';

import { LampDto } from './dto/lamp-dto';
import { IMapperFromDto } from './mappers';

/**
 * LampDto => Lamp
 */
@Injectable({
  providedIn: 'root',
})
export class LampMapper implements IMapperFromDto<LampDto, Lamp> {
  /** @inheritDoc */
  public fromDto(dto: LampDto): Lamp {
    return new Lamp({
      id: dto.lamp.id,
      name: dto.lamp.name,
      partNumber: dto.lamp.part_number,
      warrantyHours: dto.lamp.warranty_hours,
      image: dto.lamp.image,
      productUrl: dto.lamp.product_url,
      order: dto.lamp.order,
      maxLumens: dto.max_lumens,
    });
  }
}
