/**
 * Scope Aspect ratio.
 */
export const SCOPE_ASPECT_RATIO = 2.39;

/**
 * Scope Lens ratio.
 */
export const SCOPE_LENS_RATIO = 1.64;

/**
 * Scope Width 2K.
 */
export const SCOPE_WIDTH_2K = 2048;

/**
 * Scope Width 4K.
 */
export const SCOPE_WIDTH_4K = 4096;

/**
 * Scope Height 2K.
 */
export const SCOPE_HEIGHT_2K = 857;

/**
 * Scope Height 4K.
 */
export const SCOPE_HEIGHT_4K = 1714;
