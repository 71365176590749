import { ProjectorType } from '../enums';

import { EquipmentBase } from './equipment-base';
import { Lamp } from './lamp';
import { Lens } from './lens';
import { NohdLensType } from './nohd-lens-type';
import { Resolution } from './resolution';

/**
 * Projector
 */
export class Projector extends EquipmentBase {
  /** Resolution */
  public resolution: Resolution;

  /** Type */
  public type: ProjectorType;

  /** Max lumens */
  public maxLumens: number;

  /** Lenses */
  public lenses: Lens[];

  /** Lamps */
  public lamps: Lamp[];

  /** HD D USA */
  public hdDUsa: number;

  /** HD D NON USA */
  public hdDNonUsa: number;

  /** Color correction efficiency in percents. */
  public colorCorrectionEfficiencyPercent: number;

  /** NOHD lens factors. */
  public nohdLensType: readonly NohdLensType[];

  public constructor(data: ConstructorInitArg<Projector>) {
    super(data);
    this.resolution = data.resolution;
    this.type = data.type;
    this.maxLumens = data.maxLumens;
    this.lenses = data.lenses;
    this.lamps = data.lamps;
    this.hdDUsa = data.hdDUsa;
    this.hdDNonUsa = data.hdDNonUsa;
    this.colorCorrectionEfficiencyPercent = data.colorCorrectionEfficiencyPercent;
    this.nohdLensType = data.nohdLensType;
  }
}
