import { marker } from '@biesbjerg/ngx-translate-extract-marker';

/**
 * Units.
 */
export enum Units {
  /**
   * Feet.
   */
  Feet,
  /**
   * Meters.
   */
  Meters,
  /**
   * Foot-lambert.
   */
  FL,
  /**
   * Lumens.
   */
  Lumens,
}

/**
 * Helper methods for Enum.
 */
export namespace Units {
  const TO_TITLE_MAP: Record<Units, string> = {
    [Units.Feet]: marker('units.ft'),
    [Units.Meters]: marker('units.m'),
    [Units.FL]: marker('units.fL'),
    [Units.Lumens]: marker('units.lm'),
  };

  /**
   * Convert a certain Enum value to readable title.
   * @param value value of Enum
   */
  // tslint:disable-next-line: completed-docs
  export function toReadable(value: Units): string {
    return TO_TITLE_MAP[value];
  }

  /**
   * Get list of Enum items.
   */
  // tslint:disable-next-line:completed-docs
  export function toArray(): Units[] {
    return [
      Units.Feet,
      Units.Meters,
    ];
  }

  /**
   * Track by function.
   */
  // tslint:disable-next-line:completed-docs
  export function trackBy(_index: number, item: Units): Units {
    return item;
  }

  /**
   * Comparator for sort function.
   */
  // tslint:disable-next-line:completed-docs
  export function sortComparator(first: Units, second: Units): number {
    const allItems = Units.toArray();
    return allItems.indexOf(first) - allItems.indexOf(second);
  }
}
