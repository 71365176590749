import { LensOptions } from '../enums';

import { EquipmentBase } from './equipment-base';

export enum LensCode {
  UHC = 'UHC',
  HB = 'HB'
};

/**
 * Lens
 */
export class Lens extends EquipmentBase {
  /** Type */
  public type: LensOptions;

  /** Min zoom */
  public minZoom: number;

  /** Max zoom */
  public maxZoom: number;

  /** Lens code */
  public lensCode: LensCode;

  /** Is HD should be always set to 0 */
  public zeroHD: boolean;

  public constructor(data: ConstructorInitArg<Lens>) {
    super(data);
    this.type = data.type;
    this.minZoom = data.minZoom;
    this.maxZoom = data.maxZoom;
    this.lensCode = data.lensCode;
    this.zeroHD = data.zeroHD;
  }
}
