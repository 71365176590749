import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

import { FLAT_ASPECT_RATIO, SCOPE_ASPECT_RATIO } from '../constants';
import { ImageSizesValues, Size } from '../models';
import { combineValues, pickProperties, pickValue } from '../rxjs/public_api';

import { BaseSizesCalculationsService } from './base-sizes-calculations.service';
import { CalculationsFormService } from './calculations-form.service';
import { DynamicRatiosService } from './dynamic-ratios.service';
import { EquipmentService } from './equipment.service';

/**
 * Service to calculate image sizes.
 */
@Injectable({
  providedIn: 'root',
})
export class ImageSizesService {
  /**
   * @constructor
   * @param formService Form service.
   * @param baseSizesService Base sizes service.
   * @param dynamicRatiosService Dynamic ratios service.
   * @param equipmentService Equipment service.
   */
  public constructor(
    private readonly formService: CalculationsFormService,
    private readonly baseSizesService: BaseSizesCalculationsService,
    private readonly dynamicRatiosService: DynamicRatiosService,
    private readonly equipmentService: EquipmentService,
  ) { }

  private readonly lensValues$ = this.equipmentService.values$.pipe(
    pickValue('lens'),
    map(lens => ({
      maxZoom: lens?.maxZoom || 1,
      minZoom: lens?.minZoom || 1,
    })),
  );

  private readonly inputProjectionThrow$ = this.formService.values$.pipe(
    pickValue('inputProjectionThrow'),
  );

  private readonly baseSizesValues$ = this.baseSizesService.values$.pipe(
    pickProperties('flatSize', 'chipSize'),
  );

  private readonly imageValues$ = combineValues([
    this.inputProjectionThrow$,
    this.baseSizesValues$,
    this.dynamicRatiosService.values$,
  ]).pipe(
    map(([projectionThrow, { flatSize, chipSize }, { flatRatio, scopeRatio }]) => ({
      flatSize: this.calculateFlatSize(projectionThrow, flatSize.width, flatRatio, chipSize.width),
      scopeSize: this.calculateScopeSize(projectionThrow, scopeRatio),
    })),
  );

  private readonly minMaxSizesValues$ = combineValues([
    this.inputProjectionThrow$,
    this.baseSizesValues$,
    this.lensValues$,
  ]).pipe(
    map(([projectionThrow, { flatSize, chipSize }, { minZoom, maxZoom }]) => ({
      maxFlatSize: this.calculateFlatSize(projectionThrow, flatSize.width, chipSize.width, maxZoom),
      minFlatSize: this.calculateFlatSize(projectionThrow, flatSize.width, chipSize.width, minZoom),
      maxScopeSize: this.calculateScopeSize(projectionThrow, maxZoom),
      minScopeSize: this.calculateScopeSize(projectionThrow, minZoom),
    })),
  );

  /** Calculated values. */
  public readonly values$: Observable<ImageSizesValues> = combineValues([
    this.imageValues$,
    this.minMaxSizesValues$,
  ]).pipe(
    map((values) => Object.assign({}, ...values)),
    shareReplay({ bufferSize: 1, refCount: true }),
  );

  private calculateFlatSize(projectionThrow: number, flatWidth: number, ratio: number, chipWidth: number): Size {
    const widthValue = (projectionThrow * flatWidth) / (ratio * chipWidth);
    const width = Number(widthValue.toFixed(2));

    return {
      width,
      height: width / FLAT_ASPECT_RATIO,
    };
  }

  private calculateScopeSize(projectionThrow: number, ratio: number): Size {
    const widthValue = projectionThrow / ratio;
    const width = Number(widthValue.toFixed(2));

    return {
      width,
      height: width / SCOPE_ASPECT_RATIO,
    };
  }
}
