import { Injectable } from '@angular/core';
import { ProjectorType } from '@cpcac/common/core/enums';

import { Projector } from '../../models';
import { NohdLensType } from '../../models/nohd-lens-type';
import { Resolution } from '../../models/resolution';

import { ProjectorDto } from './dto/projector-dto';
import { LampMapper } from './lamp.mapper';
import { LensMapper } from './lens.mapper';
import { IMapperFromDto } from './mappers';

/**
 * ProjectorDto => Projector
 */
@Injectable({
  providedIn: 'root',
})
export class ProjectorMapper implements IMapperFromDto<ProjectorDto, Projector> {
  /**
   * @constructor
   */
  constructor(
    private readonly lampMapper: LampMapper,
    private readonly lensMapper: LensMapper,
  ) { }

  /** @inheritDoc */
  public fromDto(data: ProjectorDto): Projector {
    // TODO(Dontsov): Add logic for mapping projectorType
    const projectorType = data.type === 'RGB' ? ProjectorType.Laser4K : ProjectorType.Xenon4K;

    return new Projector({
      id: data.id,
      name: data.name,
      partNumber: data.part_number,
      resolution: new Resolution({
        name: data.resolution.name,
        width: data.resolution.width,
        height: data.resolution.height,
      }),
      type: projectorType,
      maxLumens: data.max_lumens,
      image: data.image,
      productUrl: data.product_url,
      order: data.order,
      lenses: data.lenses.map(lensDto => this.lensMapper.fromDto(lensDto)),
      lamps: data.lamps.map(lampDto => this.lampMapper.fromDto(lampDto)),
      hdDUsa: data.hd_d_usa,
      hdDNonUsa: data.hd_d_non_usa,
      colorCorrectionEfficiencyPercent: Number(data.color_correction_efficiency),
      nohdLensType: data.nohd_lens_type.map(item => new NohdLensType({
        iFactor: item.i_factor,
        lensType: item.lens_type,
        sFactor: item.s_factor,
      }))
    });
  }
}
