import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { Units } from '@cpcac/common/core/enums';
import { CalculationsFormService, HazardDistanceService } from '@cpcac/common/core/services';
import { map } from 'rxjs/operators';

/**
 * Hazard Distance Text component.
 */
@Component({
  selector: 'cpcac-hazard-distance-text',
  templateUrl: './hazard-distance-text.component.html',
  styleUrls: ['./hazard-distance-text.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HazardDistanceTextComponent {
  /** True when table for export */
  @Input()
  public exportMode = false;

  /** Selected lens hazard distance values. */
  public readonly values$ = this.hazardDistanceService.values$;

  /** Selected units. */
  public readonly units$ = this.formService.values$.pipe(
    map(form => Units.toReadable(form.units)),
  );

  /**
   * @constructor
   * @param formService Form service.
   * @param hazardDistanceService Hazard distance service.
   */
  public constructor(
    private readonly formService: CalculationsFormService,
    private readonly hazardDistanceService: HazardDistanceService,
  ) { }
}
