/** Base class for all equipments */
export class EquipmentBase {
  /** Id of equipment */
  public id: number;

  /** Name of equipment */
  public name: string;

  /** Part Number */
  public partNumber: string;

  /** Image */
  public image: string | null;

  /** Product Url */
  public productUrl: string | null;

  /** Order */
  public order: number;

  public constructor(data: ConstructorInitArg<EquipmentBase>) {
    this.id = data.id;
    this.name = data.name;
    this.partNumber = data.partNumber;
    this.image = data.image;
    this.productUrl = data.productUrl;
    this.order = data.order;
  }
}
