<button
  #tooltip="matTooltip"
  mat-icon-button
  matTooltipClass="custom-tooltip"
  [matTooltip]="text"
  [attr.aria-label]="text"
  (click)="tooltip.toggle()">
  <ng-container *ngIf="customIcon else defaultIcon">
    <mat-icon [svgIcon]="customIcon"></mat-icon>
  </ng-container>
  <ng-template #defaultIcon>
    <mat-icon>help_outline</mat-icon>
  </ng-template>
</button>
