import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

/**
 * Loading service
 *
 * This is simple loading service without support of concurrency loadings.
 * If you need concurrency loadings, need to update this service
 */
@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  private readonly loadingExportValue$ = new BehaviorSubject(false);
  private readonly loadingEquipmentValue$ = new BehaviorSubject(false);

  /** Is Export loading */
  public readonly loadingExport$ = this.loadingExportValue$.asObservable();

  /** Is Equipment loading */
  public readonly loadingEquipment$ = this.loadingEquipmentValue$.asObservable();

  /** Start Export loading */
  public startExport(): void {
    this.loadingExportValue$.next(true);
  }

  /** Finish Export loading */
  public finishExport(): void {
    this.loadingExportValue$.next(false);
  }

  /** Start Equipment loading */
  public startEquipment(): void {
    this.loadingEquipmentValue$.next(true);
  }

  /** Finish Equipment loading */
  public finishEquipment(): void {
    this.loadingEquipmentValue$.next(false);
  }
}
