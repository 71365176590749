import { Component } from '@angular/core';

/** Base app component. */
@Component({
  selector: 'cpcaw-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
}
