import { marker } from '@biesbjerg/ngx-translate-extract-marker';

/**
 * Screen configuration.
 */
export enum ScreenConfiguration {
  /**
   * Standard Aspect Fixed Width.
   */
  StandardAspectFixedWidth,
  /**
   * Standard Aspect Fixed Height.
   */
  StandardAspectFixedHeight,
}

/**
 * Helper methods for Enum.
 */
export namespace ScreenConfiguration {
  const TO_TITLE_MAP: Record<ScreenConfiguration, string> = {
    [ScreenConfiguration.StandardAspectFixedWidth]: marker('screen-configuration.standard-aspect-fixed-width'),
    [ScreenConfiguration.StandardAspectFixedHeight]: marker('screen-configuration.standard-aspect-fixed-height'),
  };

  /**
   * Convert a certain Enum value to readable title.
   * @param value value of Enum
   */
  // tslint:disable-next-line: completed-docs
  export function toReadable(value: ScreenConfiguration): string {
    return TO_TITLE_MAP[value];
  }

  /**
   * Get list of Enum items.
   */
  // tslint:disable-next-line:completed-docs
  export function toArray(): ScreenConfiguration[] {
    return [
      ScreenConfiguration.StandardAspectFixedWidth,
      ScreenConfiguration.StandardAspectFixedHeight,
    ];
  }

  /**
   * Track by function.
   */
  // tslint:disable-next-line:completed-docs
  export function trackBy(_index: number, item: ScreenConfiguration): ScreenConfiguration {
    return item;
  }

  /**
   * Comparator for sort function.
   */
  // tslint:disable-next-line:completed-docs
  export function sortComparator(first: ScreenConfiguration, second: ScreenConfiguration): number {
    const allItems = ScreenConfiguration.toArray();
    return allItems.indexOf(first) - allItems.indexOf(second);
  }

  /**
   * Check if selected screen configuration has fixed width.
   * @param value value of Enum
   */
  // tslint:disable-next-line: completed-docs
  export function isFixedWidth(value: ScreenConfiguration | null): boolean {
    return value === ScreenConfiguration.StandardAspectFixedWidth;
  }

  /**
   * Check if selected screen configuration has fixed height.
   * @param value value of Enum
   */
  // tslint:disable-next-line: completed-docs
  export function isFixedHeight(value: ScreenConfiguration | null): boolean {
    return value === ScreenConfiguration.StandardAspectFixedHeight;
  }
}
