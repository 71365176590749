import { Component, ChangeDetectionStrategy } from '@angular/core';

/**
 * Size Arrows Wrapper component.
 */
@Component({
  selector: 'cpcac-size-arrows-wrapper',
  templateUrl: './size-arrows-wrapper.component.html',
  styleUrls: ['./size-arrows-wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SizeArrowsWrapperComponent { }
