import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { distinctUntilChanged, map, shareReplay } from 'rxjs/operators';

import * as CONSTANTS from '../constants';
import { ProjectorType } from '../enums';
import { BaseSizesValues } from '../models';
import { pickValue } from '../rxjs/public_api';

import { CalculationsFormService } from './calculations-form.service';

/**
 * Base sizes calculation service.
 */
@Injectable({
  providedIn: 'root',
})
export class BaseSizesCalculationsService {
  /**
   * @constructor
   * @param formService Form service.
   */
  public constructor(private readonly formService: CalculationsFormService) { }

  private readonly defaultValues: BaseSizesValues = {
    flatSize: {
      width: 0,
      height: 0,
    },
    scopeSize: {
      width: 0,
      height: 0,
    },
    chipSize: {
      width: 0,
      height: 0,
    },
  };

  /** Calculated values. */
  public readonly values$: Observable<BaseSizesValues> = this.formService.values$.pipe(
    pickValue('projectorType'),
    distinctUntilChanged(),
    map(type => {
      let { flatSize, scopeSize, chipSize } = this.defaultValues;

      if (ProjectorType.is2K(type)) {
        flatSize = {
          width: CONSTANTS.FLAT_WIDTH_2K,
          height: CONSTANTS.FLAT_HEIGHT_2K,
        };
        scopeSize = {
          width: CONSTANTS.SCOPE_WIDTH_2K,
          height: CONSTANTS.FLAT_HEIGHT_2K,
        };
        chipSize = {
          width: CONSTANTS.CHIP_WIDTH_2K,
          height: CONSTANTS.CHIP_HEIGHT_2K,
        };
      } else if (ProjectorType.is4K(type)) {
        flatSize = {
          width: CONSTANTS.FLAT_WIDTH_4K,
          height: CONSTANTS.FLAT_HEIGHT_4K,
        };
        scopeSize = {
          width: CONSTANTS.SCOPE_WIDTH_4K,
          height: CONSTANTS.FLAT_HEIGHT_4K,
        };
        chipSize = {
          width: CONSTANTS.CHIP_WIDTH_4K,
          height: CONSTANTS.CHIP_HEIGHT_4K,
        };
      }

      return { flatSize, scopeSize, chipSize };
    }),
    shareReplay({ bufferSize: 1, refCount: true }),
  );
}
