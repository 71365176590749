import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

import { CHIP_ASPECT_RATIO, FLAT_ASPECT_RATIO, IMAGE_SAFETY_FACTOR, SCOPE_ASPECT_RATIO } from '../constants';
import { ScreenConfiguration } from '../enums';
import { DynamicRatiosValues, ImageValues } from '../models';
import { combineValues, pickProperties } from '../rxjs/public_api';

import { CalculationsFormService } from './calculations-form.service';
import { ImageAnalysisService } from './image-analysis.service';

/**
 * Service to calculate values for dynamic ratios.
 */
@Injectable({
  providedIn: 'root',
})
export class DynamicRatiosService {
  /**
   * @constructor
   * @param formService Form service.
   * @param imageAnalysisService Image calculations service.
   */
  public constructor(
    private readonly formService: CalculationsFormService,
    private readonly imageAnalysisService: ImageAnalysisService,
  ) { }

  private readonly formValues$ = this.formService.values$.pipe(
    pickProperties('inputProjectionThrow', 'screenConfiguration', 'flatWidth', 'flatHeight', 'scopeWidth'),
  );

  /** Calculated values. */
  public readonly values$: Observable<DynamicRatiosValues> = combineValues([
    this.formValues$,
    this.imageAnalysisService.values$,
  ]).pipe(
    map(([form, image]) => {
      let flatRatioValue = 1;
      let scopeRatioValue = 1;

      if (ScreenConfiguration.isFixedWidth(form.screenConfiguration)) {
        const flatCoef = this.calculateFlatCoefficient(image, form.flatWidth, form.flatHeight);
        flatRatioValue = this.calculateRatio(form.inputProjectionThrow, flatCoef);

        const scopeCoef = this.calculateScopeCoefficient(image, form.scopeWidth);
        scopeRatioValue = this.calculateRatio(form.inputProjectionThrow, scopeCoef);

      } else if (ScreenConfiguration.isFixedHeight(form.screenConfiguration)) {
        flatRatioValue = this.calculateRatio(form.inputProjectionThrow, form.flatHeight * image.flatChipWidthRatio);
        scopeRatioValue = this.calculateRatio(form.inputProjectionThrow, form.scopeWidth);
      }

      const flatRatio = Number(flatRatioValue.toFixed(2));
      const scopeRatio = Number(scopeRatioValue.toFixed(2));

      const maxZoomRequired = Math.min(flatRatioValue, scopeRatioValue);
      const minZoomRequired = Math.max(flatRatioValue, scopeRatioValue);

      return { flatRatio, scopeRatio, maxZoomRequired, minZoomRequired };
    }),
    shareReplay({ bufferSize: 1, refCount: true }),
  );

  private calculateFlatCoefficient(image: ImageValues, width: number, height: number): number {
    if (image.scopeImageSize.height / image.flatImageSize.height <= FLAT_ASPECT_RATIO) {
      return width * image.flatChipWidthRatio;
    }

    return height * CHIP_ASPECT_RATIO;
  }

  private calculateScopeCoefficient(image: ImageValues, width: number): number {
    if (image.scopeImageSize.width / image.scopeImageSize.height <= SCOPE_ASPECT_RATIO) {
      return width;
    }

    return image.scopeImageSize.height * SCOPE_ASPECT_RATIO;
  }

  private calculateRatio(projectionThrow: number, coef: number): number {
    return projectionThrow / ((IMAGE_SAFETY_FACTOR + 1) * coef);
  }
}
