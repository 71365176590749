import { MediaMatcher } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

/**
 * Service with mobile logic
 */
@Injectable({
  providedIn: 'root',
})
export class MobileService {
  private readonly BREAKPOINT_MOBILE = '769px';

  /** Is Mobile sidebar opened */
  public mobileSidebarOpened = true;

  private readonly isMobileValue$ = new BehaviorSubject(false);
  private readonly isMobileSidebarOpenedValue$ = new BehaviorSubject(true);

  /** Is Mobile or not */
  public isMobile$ = this.isMobileValue$.asObservable();

  /** Is Sidebar Opened (only for mobile) */
  public isMobileSidebarOpened$ = this.isMobileSidebarOpenedValue$.asObservable();

  /**
   * @constructor
   */
  public constructor(
    media: MediaMatcher,
  ) {
    const mobileQuery = media.matchMedia(`(max-width: ${this.BREAKPOINT_MOBILE})`);
    this.isMobileValue$.next(mobileQuery.matches);
    mobileQuery.addEventListener('change', () => {
      this.isMobileValue$.next(mobileQuery.matches);
    });
  }

  /** Toggle sidebar */
  public toggleSidebar(value?: boolean): void {
    const nextValue = value ?? !this.isMobileSidebarOpenedValue$.getValue();
    this.isMobileSidebarOpenedValue$.next(nextValue);

    // Scroll to top
    window.scroll(0, 0);
  }

  /** Get not observable isMobile value */
  public get isMobile(): boolean {
    return this.isMobileValue$.getValue();
  }
}
