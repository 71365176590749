/**
 * Chip Aspect ratio.
 */
export const CHIP_ASPECT_RATIO = 1.8963;

/**
 * Chip Fixed Width Aspect ratio.
 * ({Chip Width} / {Chip Height})
 */
export const CHIP_FIXED_WIDTH_ASPECT_RATIO = 1.025;

/**
 * Chip Width 2K.
 */
export const CHIP_WIDTH_2K = 2048;

/**
 * Chip Width 4K.
 */
export const CHIP_WIDTH_4K = 4096;

/**
 * Chip Height 2K.
 */
export const CHIP_HEIGHT_2K = 1080;

/**
 * Chip Height 4K.
 */
export const CHIP_HEIGHT_4K = 2160;
