import { Injectable } from '@angular/core';

import { LensOptions } from '../../enums';
import { Lens } from '../../models/lens';

import { LensDto } from './dto/lens-dto';
import { IMapperFromDto } from './mappers';

/**
 * LensDto => Lens
 */
@Injectable({
  providedIn: 'root',
})
export class LensMapper implements IMapperFromDto<LensDto, Lens> {
  /** @inheritDoc */
  public fromDto(lensDto: LensDto): Lens {
    return new Lens({
      id: lensDto.lens.id,
      name: lensDto.lens.name,
      partNumber: lensDto.lens.part_number,
      type: lensDto.lens.type as LensOptions,
      minZoom: lensDto.lens.min_zoom,
      maxZoom: lensDto.lens.max_zoom,
      image: lensDto.lens.image,
      productUrl: lensDto.lens.product_url,
      order: lensDto.lens.order,
      lensCode: lensDto.lens.lens_id,
      zeroHD: lensDto.zero_hd,
    });
  }
}
