import { Directive, Input, TemplateRef, ViewContainerRef, OnInit } from '@angular/core';

/** Context of NgLet */
export class NgLetContext<T> {
  /** $implicit */
  public $implicit: T | null = null;
  /** ngLet */
  public ngLet: T | null = null;
}

/**
 * NgLet directive, like ngIf but not hide the container
 *
 * We often use *ngIf="stream$ | async as stream" to subscribe to an observable property
 * and rename it to a template variable. But with nested template,
 * *ngIf might remove your template which may not be expected.
 */
@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[ngLet]',
})
export class NgLetDirective<T> implements OnInit {
  private context = new NgLetContext<T>();

  /** Value */
  @Input()
  set ngLet(value: T) {
    this.context.$implicit = this.context.ngLet = value;
  }

  /** Asserts the correct type of the context for the template that `NgIf` will render. */
  public static ngTemplateContextGuard<T>(
    _dir: NgLetDirective<T>,
    _ctx: unknown,
  ): _ctx is NgLetContext<Exclude<T, false | 0 | '' | null | undefined>> {
    return true;
  }

  constructor(
    private readonly vcr: ViewContainerRef,
    private readonly templateRef: TemplateRef<NgLetContext<T>>,
  ) {}

  /** @inheritDoc */
  public ngOnInit(): void {
    this.vcr.createEmbeddedView<NgLetContext<T>>(this.templateRef, this.context);
  }
}
