<cpcac-calculations-table class="export-table">
  <thead>
    <th translate>section-header.equipment-selection</th>
    <th translate colspan="2">equipment.model-description</th>
    <th translate>equipment.part-number</th>
  </thead>
  <tbody *ngIf="selectedEquipment$ | async as values">
    <tr *ngIf="values?.projector as projector">
      <td>{{ 'equipment.projector' | translate }}</td>
      <td colspan="2">{{ projector?.name }}</td>
      <td>{{ projector?.partNumber }}</td>
    </tr>
    <ng-container *ngIf="shouldSelectLamp$|async">
      <tr *ngIf="values?.lamp as lamp">
        <td>{{ 'equipment.lamp' | translate }}</td>
        <td colspan="2">{{ lamp?.name }}</td>
        <td>{{ lamp?.partNumber }}</td>
      </tr>
    </ng-container>
    <tr *ngIf="values?.lens as lens">
      <td>{{ 'equipment.lens' | translate }}</td>
      <td colspan="2">{{ lens?.name }}</td>
      <td>{{ lens?.partNumber }}</td>
    </tr>
    <tr>
      <td>{{ 'inputs-section.screen-gain' | translate }}</td>
      <td>{{ screenGain$ | async }}</td>
      <td>{{ 'inputs-section.input-screen-gain' | translate }}</td>
      <td></td>
    </tr>
  </tbody>
</cpcac-calculations-table>
