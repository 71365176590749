import { EquipmentBase } from './equipment-base';

/**
 * Lamp
 */
export class Lamp extends EquipmentBase {
  /** Max lumens */
  public maxLumens: number;

  /** Warranty hours */
  public warrantyHours: number;

  public constructor(data: ConstructorInitArg<Lamp>) {
    super(data);
    this.maxLumens = data.maxLumens;
    this.warrantyHours = data.warrantyHours;
  }
}
