/**
 * Flat Aspect ratio.
 */
export const FLAT_ASPECT_RATIO = 1.85;

/**
 * Flat Lens ratio.
 */
export const FLAT_LENS_RATIO = 2.07;

/**
 * Flat Width 2K.
 */
export const FLAT_WIDTH_2K = 1998;

/**
 * Flat Width 4K.
 */
export const FLAT_WIDTH_4K = 3996;

/**
 * Flat Height 2K.
 */
export const FLAT_HEIGHT_2K = 1080;

/**
 * Flat Height 4K.
 */
export const FLAT_HEIGHT_4K = 2160;
