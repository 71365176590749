import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

import { FlatScopeValues } from '../models';
import { combineValues, pickProperties, pickValue } from '../rxjs/public_api';

import { CalculationsFormService } from './calculations-form.service';
import { EfficiencyPercentCalculationsService } from './efficiency-percent-calculations.service';
import { ImageAnalysisService } from './image-analysis.service';

/**
 * Service to calculate lumens required values.
 */
@Injectable({
  providedIn: 'root',
})
export class LumensRequiredService {
  /**
   * @constructor
   * @param formService Form service.
   * @param efficiencyPercentService Efficiency percent calculations service.
   * @param imageService Image service.
   */
  public constructor(
    private readonly formService: CalculationsFormService,
    private readonly efficiencyPercentService: EfficiencyPercentCalculationsService,
    private readonly imageService: ImageAnalysisService,
  ) { }

  private readonly formValuesForCalculations$ = this.formService.values$.pipe(
    pickProperties('screenGain', 'portWindowEfficiency', 'centerBrightness', 'centerBrightness3D', 'systemEfficiency3D'),
  );
  private readonly efficiencyValue$ = this.efficiencyPercentService.values$.pipe(
    pickValue('colorCorrectionEfficiencyPercent'),
  );

  /** Lumens required calculations base formula. */
  public readonly lumensRequiredCalculationBase$ = combineValues([
    this.formValuesForCalculations$,
    this.efficiencyValue$,
  ]).pipe(
    map(([{ screenGain, portWindowEfficiency }, colorCorrectionEfficiencyPercent]) => {
      // Note: We must divide by 100 because we need percent.
      return screenGain * (portWindowEfficiency / 100) * (colorCorrectionEfficiencyPercent / 100);
    }),
  );

  /** Calculated values. */
  public readonly values$: Observable<FlatScopeValues> = combineValues([
    this.lumensRequiredCalculationBase$,
    this.formValuesForCalculations$,
    this.imageService.values$,
  ]).pipe(
    map(([base,
      { centerBrightness, centerBrightness3D, systemEfficiency3D },
      { flatCenterLumenArea, scopeCenterLumenArea },
    ]) => ({
      flat: (centerBrightness * flatCenterLumenArea) / base,
      // Note: we divide by 100 because we use percent.
      flat3D: (centerBrightness3D * flatCenterLumenArea) / (base * systemEfficiency3D / 100),
      scope: (centerBrightness * scopeCenterLumenArea) / base,
      scope3D: (centerBrightness3D * scopeCenterLumenArea) / (base * systemEfficiency3D / 100),
    })),
    shareReplay({ bufferSize: 1, refCount: true }),
  );
}
