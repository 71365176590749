import { Component, ChangeDetectionStrategy, ViewEncapsulation } from '@angular/core';

/**
 * Calculations Table component.
 */
@Component({
  selector: 'cpcac-calculations-table',
  templateUrl: './calculations-table.component.html',
  styleUrls: ['./calculations-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class CalculationsTableComponent { }
