<cpcac-app-card color="accent">
  <div class="header" card-header>
    <h3 class="card-title">{{ title }}</h3>
    <ng-content select="[header]"></ng-content>
    <cpcac-tooltip-button [text]="tipText"></cpcac-tooltip-button>
  </div>
  <!-- Select Equipment -->
  <mat-form-field color="accent" floatLabel="never">
    <mat-select
      [placeholder]="'select.placeholder'|translate"
      [value]="value"
      (valueChange)="setValue($event)">
      <ng-container *ngIf="!options || !options?.length; else optionsSection">
        <mat-option [disabled]="true">
          <span *ngIf="!options; else notFound" translate>
            equipment.not-loaded
          </span>
          <ng-template #notFound>
            <span translate>equipment.not-found</span>
          </ng-template>
        </mat-option>
      </ng-container>

      <ng-template #optionsSection>
        <mat-option
          *ngFor="let option of options"
          [class.is-suitable]="option.isSuitable"
          [value]="option">
          {{option.name}}
        </mat-option>
      </ng-template>
    </mat-select>
  </mat-form-field>

  <div *ngIf="isEmpty; else content">
    <cpcac-fake-equipment-item [withHeader]="false">
    </cpcac-fake-equipment-item>
    <h3 class="empty-item-message">{{ emptyItemMessage }}</h3>
  </div>

  <ng-template #content>
    <div class="item">
      <a
        *ngIf="value?.productUrl"
        mat-button
        class="link"
        target="_blank"
        rel="noopener noreferrer"
        title="link"
        [href]="value?.productUrl">
        <mat-icon>launch</mat-icon>
      </a>

      <img
        *ngIf="value?.image"
        class="image-preview"
        [src]="value?.image"
        alt="Equipment image">
      <div class="item-description">
        <span class="title">{{ value?.name }}</span>
        <span class="subtitle">{{ value?.partNumber }}</span>
        <span class="description">
          <ng-content></ng-content>
        </span>
      </div>
    </div>
  </ng-template>
</cpcac-app-card>
