import { Resolution } from '../models/resolution';

import { CHIP_HEIGHT_2K, CHIP_HEIGHT_4K, CHIP_WIDTH_2K, CHIP_WIDTH_4K } from './chip-constants';

export const RESOLUTION_2K = new Resolution({
  name: '2K',
  width: CHIP_WIDTH_2K,
  height: CHIP_HEIGHT_2K,
});

export const RESOLUTION_4K = new Resolution({
  name: '4K',
  width: CHIP_WIDTH_4K,
  height: CHIP_HEIGHT_4K,
});
