import { AfterViewInit, ChangeDetectorRef, Directive, ElementRef, EventEmitter, forwardRef, HostListener, Output, Provider } from '@angular/core';
import { FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from '@angular/forms';
import { ValidationErrorCode } from '@cpcac/common/core/models/validation-error-code';
import intlTelInput from 'intl-tel-input';

import { ControlValueAccessorBase } from '../utils/control-value-accessor-base';

const CONTROL_ACCESS_PROVIDER: Provider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => PhoneInputDirective),
  multi: true,
};

const VALIDATOR_PROVIDER: Provider = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => PhoneInputDirective),
  multi: true,
};

/**
 * Validation errors.
 */
enum TelValidationError {
  /** The number length matches that of valid numbers for this region. */
  IS_POSSIBLE = 0,
  /**
   * The number length matches that of local numbers for this region only (i.e.
   * numbers that may be able to be dialled within an area, but do not have all
   * the information to be dialled from anywhere inside or outside the country).
   */
  IS_POSSIBLE_LOCAL_ONLY = 4,
  /** The number has an invalid country calling code. */
  INVALID_COUNTRY_CODE = 1,
  /** The number is shorter than all valid numbers for this region. */
  TOO_SHORT = 2,
  /**
   * The number is longer than the shortest valid numbers for this region,
   * shorter than the longest valid numbers for this region, and does not itself
   * have a number length that matches valid numbers for this region.
   * This can also be returned in the case where
   * isPossibleNumberForTypeWithReason was called, and there are no numbers of
   * this type at all for this region.
   */
  INVALID_LENGTH = 5,
  /** The number is longer than all valid numbers for this region. */
  TOO_LONG = 3,
}

const ERROR_MAP: Record<TelValidationError, string> = {
  [TelValidationError.IS_POSSIBLE]: 'Invalid phone number',
  [TelValidationError.INVALID_COUNTRY_CODE]: 'Invalid country code',
  [TelValidationError.TOO_SHORT]: 'Too few digits',
  [TelValidationError.TOO_LONG]: 'Too many digits',
  [TelValidationError.INVALID_LENGTH]: 'Invalid phone number',
  [TelValidationError.IS_POSSIBLE_LOCAL_ONLY]: 'Invalid phone number',
};

const DEFAULT_INVALID_PHONE_MESSAGE = 'Invalid phone number';

/** The library does not provide a enum value for this but "-99 is default" */
const DEFAULT_INVALID_ERROR_CODE = -99;

function isErrorInvalidNumber(errorCode: TelValidationError | typeof DEFAULT_INVALID_ERROR_CODE): boolean {
  return errorCode === TelValidationError.IS_POSSIBLE
    || errorCode === TelValidationError.INVALID_LENGTH
    || errorCode === DEFAULT_INVALID_ERROR_CODE
    || errorCode === TelValidationError.IS_POSSIBLE_LOCAL_ONLY;
}

const PHONE_VALIDATION_REGEX = /^\+{0,1}[0-9|\s|(|)|-]*$/;
const FORBIDDEN_SYMBOLS_MESSAGE = 'Phone number contains unsupported characters.';

/**
 * Phone input directive. Provide functionality for phone validation according selected country format.
 */
@Directive({
  selector: 'input[cpcacPhoneInput]',
  providers: [CONTROL_ACCESS_PROVIDER, VALIDATOR_PROVIDER],
})
export class PhoneInputDirective extends ControlValueAccessorBase<string> implements AfterViewInit, Validator {
  /** Country from choosing phone number.*/
  @Output()
  public countryOfPhoneChanged = new EventEmitter<string>();

  private readonly input: HTMLInputElement;

  private iti!: intlTelInput.Plugin;

  public constructor(
    elementRef: ElementRef<HTMLInputElement>,
    cdr: ChangeDetectorRef,
  ) {
    super(cdr);
    this.input = elementRef.nativeElement;
  }

  /**
   * Handle 'input' value of the input element.
   * @param value Control value.
   */
  @HostListener('input')
  public onInputChange(): void {
    this.setValue(this.iti.getNumber());
  }

  /** Handle 'blur' value of input element. */
  @HostListener('blur')
  public onBlur(): void {
    this.onTouched();
  }

  /**
   * Handle 'countrychange' value of the iti lib.
   */
  @HostListener('countrychange')
  public onCountryChange(): void {
    const selectedCountry = this.iti.getSelectedCountryData();
    if (selectedCountry.name) {
      this.countryOfPhoneChanged.emit(selectedCountry.name);
    }
  }

  /** @inheritdoc */
  public writeValue(initialValue: string | null): void {
    const initValue = initialValue ?? '';
    if (this.iti && initValue) {
      this.iti.setNumber(initValue);
    } else {
      this.input.value = initValue;
    }
    super.writeValue(initValue);
  }

  /** @inheritdoc */
  public ngAfterViewInit(): void {
    this.iti = intlTelInput(this.input, {
      initialCountry: 'US',
      preferredCountries: ['us'],
      utilsScript: '/node_modules/intl-tel-input/build/js/utils.js',
      nationalMode: true,
      separateDialCode: true,
    });
    this.onCountryChange();
  }

  /** @inheritdoc */
  public validate(control: FormControl): ValidationErrors | null {
    if (control.pristine || !this.iti) {
      return null;
    }
    if (PHONE_VALIDATION_REGEX.test(this.input.value) === false) {
      return {
        [ValidationErrorCode.AppError]: {
          message: FORBIDDEN_SYMBOLS_MESSAGE,
        },
      };
    }
    if (this.iti.isValidNumber() === true || this.iti.getNumber() === '') {
      return null;
    }

    /*
      Cast intentionally because types don't match the real values.

      Types from the intl-tel-phone library:
      https://github.com/DefinitelyTyped/DefinitelyTyped/blob/master/types/intl-tel-input/index.d.ts#L314

      Value from the google library that's used internally in intl-tel-phone:
      https://github.com/google/libphonenumber/blob/master/javascript/i18n/phonenumbers/phonenumberutil.js#L1038
    */
    const itiErrorCode = this.iti.getValidationError() as any as TelValidationError;
    let errorMessage = ERROR_MAP[itiErrorCode] ?? DEFAULT_INVALID_PHONE_MESSAGE;
    if (
      (isErrorInvalidNumber(itiErrorCode) || errorMessage === DEFAULT_INVALID_PHONE_MESSAGE ) &&
      this.inputPlaceholder
    ) {
      // Placeholder keeps the right format according to selected country.
      errorMessage += `. Format: ${this.inputPlaceholder}`;
    }

    return {
      [ValidationErrorCode.AppError]: {
        message: errorMessage,
      },
    };
  }

  private get inputPlaceholder(): string {
    return this.input.placeholder;
  }
}
