import { LensCode } from './../models/lens';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

import { FEET_TO_METERS_RATIO, NO_ACCESS_ZONE_METER } from '../constants';
import { Units } from '../enums';
import { HazardDistanceValues } from '../models';
import { combineValues, pickValue } from '../rxjs/public_api';

import { CalculationsFormService } from './calculations-form.service';
import { DynamicRatiosService } from './dynamic-ratios.service';
import { EquipmentService } from './equipment.service';

/**
 * Service to calculate hazard distance.
 */
@Injectable({
  providedIn: 'root',
})
export class HazardDistanceService {
  /**
   * @constructor
   * @param formService Form service.
   * @param equipmentService Equipment service.
   * @param ratiosService Ratios service.
   */
  public constructor(
    private readonly formService: CalculationsFormService,
    private readonly equipmentService: EquipmentService,
    private readonly ratiosService: DynamicRatiosService,
  ) { }

  /** Calculated values. */
  public readonly values$: Observable<HazardDistanceValues> = combineValues([
    this.equipmentService.values$.pipe(pickValue('projector')),
    this.equipmentService.values$.pipe(pickValue('lens')),
    this.ratiosService.values$.pipe(pickValue('minZoomRequired')),
    this.formService.values$.pipe(pickValue('units')),
  ]).pipe(
    map(([projector, lens, zoom, units]) => {
      let noAccessZone = NO_ACCESS_ZONE_METER * FEET_TO_METERS_RATIO;
      if (projector && lens) {
        let { hdDUsa, hdDNonUsa } = projector;
        let hdB = 1; // Note: hdB is in meters by default

        switch (lens.lensCode) {
          case 'HB':
            const nohdLensHB = projector.nohdLensType.find(i => i.lensType === LensCode.HB);
            if (nohdLensHB) {
              hdB = (zoom * nohdLensHB.sFactor) + nohdLensHB.iFactor;
            }
            break;
          case 'UHC':
            const nohdLensUHC = projector.nohdLensType.find(i => i.lensType === LensCode.UHC);
            if (nohdLensUHC) {
              hdB = (zoom * nohdLensUHC.sFactor) + nohdLensUHC.iFactor;
            }
            break;
        }

        if (units === Units.Meters) {
          hdDUsa /= FEET_TO_METERS_RATIO;
          hdDNonUsa /= FEET_TO_METERS_RATIO;
          noAccessZone /= NO_ACCESS_ZONE_METER;
          hdB = hdB < 1.0 ? 1.0 : hdB;
        } else if (units === Units.Feet) {
          const hdBFeet = hdB * FEET_TO_METERS_RATIO;
          hdB = hdBFeet < 1.0 ? 1.0 * FEET_TO_METERS_RATIO : hdBFeet;
        }

        if (lens.zeroHD) {
          hdDUsa = 0;
          hdDNonUsa = 0;
          hdB = 0;
        }

        return { hdDUsa, hdDNonUsa, hdB, noAccessZone };
      } else {
        return {
          hdDUsa: 0,
          hdDNonUsa: 0,
          hdB: 0,
          noAccessZone,
        };
      }
    }),
    shareReplay({ bufferSize: 1, refCount: true }),
  );
}
