import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

/**
 * App card component.
 */
@Component({
  selector: 'cpcac-fake-equipment-item',
  templateUrl: './fake-equipment-item.component.html',
  styleUrls: ['./fake-equipment-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FakeEquipmentItemComponent {
  /** With header row or not */
  @Input()
  public withHeader = true;
}
