import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { FlatScopeValues } from '@cpcac/common/core/models';

/**
 * Calculations Table row component.
 */
@Component({
  selector: 'cpcac-calculations-table-row',
  templateUrl: './calculations-table-row.component.html',
  styleUrls: ['./calculations-table-row.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CalculationsTableRowComponent {
  /** Row data item. */
  @Input() public rowData?: Partial<FlatScopeValues> | null;

  /** Should display 2D and 3D columns. */
  @Input() public show3D?: boolean | null;

  /** Value units. */
  @Input() public units?: string | null;

  /** Value units. */
  @Input() public fractionDigits?: number;

  /** True when table for export. */
  @Input()
  public exportMode = false;
}
