import { Component, ChangeDetectionStrategy } from '@angular/core';
import { pickValue } from '@cpcac/common/core/rxjs/pick-value';
import { CalculationsFormService } from '@cpcac/common/core/services';
import { EquipmentService } from '@cpcac/common/core/services/equipment.service';

/**
 * Hazard distance table component.
 */
@Component({
  selector: 'cpcac-equipment-table',
  templateUrl: './equipment-table.component.html',
  styleUrls: ['./equipment-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EquipmentTableComponent {
  /** Selected equipment. */
  public readonly selectedEquipment$ = this.equipmentService.values$;

  /** Should display Lamp selection or not. */
  public readonly shouldSelectLamp$ = this.equipmentService.shouldSelectLamp$;

  /** Screen gaim. */
  public readonly screenGain$ = this.formService.values$.pipe(
    pickValue('screenGain'),
  );

  /** @constructor */
  public constructor(
    private readonly equipmentService: EquipmentService,
    private readonly formService: CalculationsFormService,
  ) {
  }
}
