/**
 * Resolution
 */
export class Resolution {
  /** Name */
  public name: string;
  /** Width */
  public width: number;
  /** Height */
  public height: number;

  public constructor(data: ConstructorInitArg<Resolution>) {
    this.name = data.name;
    this.width = data.width;
    this.height = data.height;
  }
}
