<div [class.export-block]="exportMode">
  <h4>A – {{ 'hazard-distance-illustrtion.hazard-zone.header' | translate }}</h4>
  <p translate>hazard-distance-illustrtion.hazard-zone.description</p>
  <h4>B – {{ 'hazard-distance-illustrtion.hazard-distance.header' | translate }}</h4>
  <p translate>hazard-distance-illustrtion.hazard-distance.description</p>
  <h4>C – {{ 'hazard-distance-illustrtion.no-access-zone.header' | translate }}</h4>
  <p>
    {{ 'hazard-distance-illustrtion.no-access-zone.description' | translate:{ 
      value: (values$ | async)?.noAccessZone | roundNumber:'1',
      units: (units$ | async) || '' | translate
    }
    }}
  </p>
  <h4>D – {{ 'hazard-distance-illustrtion.vertical-distance.header' | translate }}</h4>
  <p>
    {{ 'hazard-distance-illustrtion.vertical-distance.description' | translate:{ 
      usa: (values$ | async)?.hdDUsa | roundNumber:'2',
      nonUSA: (values$ | async)?.hdDNonUsa | roundNumber:'2',
      units: (units$ | async) || '' | translate
    }
    }}
  </p>
  <h4>E – {{ 'hazard-distance-illustrtion.top-view-of-projector.header' | translate }}</h4>
  <p></p>
  <h4>F – {{ 'hazard-distance-illustrtion.side-view-of-projector.header' | translate }}</h4>
</div>
