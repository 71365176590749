<td>
  <ng-content select="[row-caption]"></ng-content>
</td>
<ng-container *ngIf="show3D then cols3D else cols2D">
</ng-container>
<td *ngIf="!exportMode"></td>
<td><ng-content select="[notes]"></ng-content></td>

<ng-template #cols2D>
  <td class="nowrap">
    {{ rowData?.flat | roundNumber: fractionDigits }}
    {{ units }}
  </td>
  <td class="nowrap">
    {{ rowData?.scope | roundNumber: fractionDigits }}
    {{ units }}
  </td>
</ng-template>

<ng-template #cols3D>
  <td class="nowrap" [colSpan]="rowData?.flat3D ? 1 : 2">
    {{ rowData?.flat | roundNumber: fractionDigits }}
    {{ units }}
  </td>
  <td *ngIf="rowData?.flat3D" class="nowrap">
    {{ rowData?.flat3D | roundNumber: fractionDigits }}
    {{ units }}
  </td>
  <td class="nowrap" [colSpan]="rowData?.scope3D ? 1 : 2">
    {{ rowData?.scope | roundNumber: fractionDigits }}
    {{ units }}
  </td>
  <td *ngIf="rowData?.scope3D" class="nowrap">
    {{ rowData?.scope3D | roundNumber: fractionDigits }}
    {{ units }}
  </td>
</ng-template>
