import { MonoTypeOperatorFunction, throwError, ObservableInput } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AppValidationError } from '../models/app-error';

/**
 * Catch application validation error (instance of AppValidationError) operator.
 * Catches only AppValidationError<T> errors.
 * @param selector Selector.
 */
export function catchValidationError<T, O extends ObservableInput<any>, TEntity extends object = T extends object ? T : object>(
  selector: (error: AppValidationError<TEntity>) => O): MonoTypeOperatorFunction<T> {
  return catchError(error => {
    if (error instanceof AppValidationError) {
      return selector(error);
    }
    return throwError(error);
  });
}
