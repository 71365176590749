import { Component, ChangeDetectionStrategy } from '@angular/core';

/**
 * Hazard Distance Illustration component.
 */
@Component({
  selector: 'cpcac-hazard-distance-illustration',
  templateUrl: './hazard-distance-illustration.component.html',
  styleUrls: ['./hazard-distance-illustration.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HazardDistanceIllustrationComponent { }
